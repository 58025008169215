import { config, library } from '@fortawesome/fontawesome-svg-core';

// styles.css wird in layout.tsx importiert, da sonst die styles doppelt geladen werden.
// import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

import {
  faAddressCard, faArrowLeftLong, faBell, faCalendar, faCalendarPlus, faCalendarXmark, faClone, faEdit, faEnvelopeOpen,
  faFileCircleMinus, faFileCirclePlus, faFileExport, faFileImport, faHand, faInfo, faLink, faLinkSlash, faLock,
  faMagnifyingGlass, faPeopleRoof, faPlus, faPowerOff, faQuestionCircle, faSave, faStopwatch, faToolbox, faTrash, faUnlock, faUser,
  faUserEdit, faUserLock, faUserPlus, faUsers, faX
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppIcon from "./AppIcon";

library.add(faQuestionCircle, faUser, faPowerOff, faTrash, faPlus, faArrowLeftLong, faX, faSave, faEdit,
  faFileCirclePlus, faFileCircleMinus, faLink, faLinkSlash, faCalendarPlus, faCalendarXmark, faCalendar, faUserLock,
  faUserPlus, faUsers, faClone, faBell, faHand, faInfo, faAddressCard, faEnvelopeOpen, faUserEdit, faStopwatch,
  faFileImport, faFileExport, faUnlock, faLock, faPeopleRoof, faMagnifyingGlass, faToolbox);

export namespace AppIcons {
  export const UserIcon = (<FontAwesomeIcon icon={"user"}/>) as AppIcon;
  export const PowerOffIcon = (<FontAwesomeIcon icon={"power-off"}/>) as AppIcon;
  export const CircleQuestionIcon = (<FontAwesomeIcon icon={"question-circle"}/>) as AppIcon;
  export const TrashIcon = (<FontAwesomeIcon icon={"trash"}/>) as AppIcon;
  export const PlusIcon = (<FontAwesomeIcon icon={"plus"}/>) as AppIcon;
  export const Back = (<FontAwesomeIcon icon={"arrow-left-long"}/>) as AppIcon;
  export const Cancel = (<FontAwesomeIcon icon={"x"}/>) as AppIcon;
  export const EditIcon = (<FontAwesomeIcon icon={"edit"}/>) as AppIcon;
  export const SaveIcon = (<FontAwesomeIcon icon={"save"}/>) as AppIcon;
  export const CreateCompetition = (<FontAwesomeIcon icon={"calendar-plus"}/>) as AppIcon;
  export const RemoveCompetition = (<FontAwesomeIcon icon={"trash"}/>) as AppIcon;
  export const AddLink = (<FontAwesomeIcon icon={"link"}/>) as AppIcon;
  export const RemoveLink = (<FontAwesomeIcon icon={"link-slash"}/>) as AppIcon;
  export const AddDocument = (<FontAwesomeIcon icon={"file-circle-plus"}/>) as AppIcon;
  export const RemoveDocument = (<FontAwesomeIcon icon={"file-circle-minus"}/>) as AppIcon;
  export const RemovePermission = (<FontAwesomeIcon icon={"trash"}/>) as AppIcon;
  export const RequestCompetitionPermission = (<FontAwesomeIcon icon={"user-lock"}/>) as AppIcon;
  export const RequestPermission = (<FontAwesomeIcon icon={"unlock"}/>) as AppIcon;
  export const Permissions = (<FontAwesomeIcon icon={"users"}/>) as AppIcon;
  export const CreateRegistrableCompetition = (<FontAwesomeIcon icon={"address-card"}/>) as AppIcon;
  export const GoToRegistrableCompetition = (<FontAwesomeIcon icon={"address-card"}/>) as AppIcon;
  export const CreateRegistration = (<FontAwesomeIcon icon={"envelope-open"}/>) as AppIcon;
  export const EditAthlete = (<FontAwesomeIcon icon={"user-edit"}/>)
  export const EditTimes = <FontAwesomeIcon icon={"stopwatch"}/>;
  export const DeleteAthlete = <FontAwesomeIcon icon={"trash"}/>;
  export const AddAthlete = <FontAwesomeIcon icon={"user-plus"}/>;
  export const AddAthleteSmall = <FontAwesomeIcon icon={"user-plus"}/>;
  export const AddTeam = <FontAwesomeIcon icon={"user-plus"}/>;
  export const EditTeam = <FontAwesomeIcon icon={"user-edit"}/>;
  export const EditTeamMembers = <FontAwesomeIcon icon={"users"}/>;
  export const DeleteTeam = <FontAwesomeIcon icon={"trash"}/>;
  export const ImportFile = <FontAwesomeIcon icon={"file-export"}/>;
  export const CloseRegistration = <FontAwesomeIcon icon={"lock"}/>;
  export const ReopenRegistration = <FontAwesomeIcon icon={"unlock"}/>;
  export const ValidateRegistration = <FontAwesomeIcon icon={"lock"} />;
  export const RemoveRegistration = <FontAwesomeIcon icon={"trash"}/>;
  export const RemoveTeamMember = <FontAwesomeIcon icon={"x"}/>;
  export const Search = <FontAwesomeIcon icon={"magnifying-glass"}/>;
  export const Organization = <FontAwesomeIcon icon={"people-roof"}/>;
  export const Administration = <FontAwesomeIcon icon={"toolbox"}/>;
  export const RemoveRegistrableCompetition = <FontAwesomeIcon icon={"trash"} />;
}
