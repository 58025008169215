"use client";

import { OrganizationModel } from "@/components/global/layout/OrganizationSelect/OrganizationModel";
import UserStatus from "@/components/global/layout/UserStatus/UserStatus";
import { OrganizationInfo } from "@/app/layout";

type Props = {
  lang: string;
  organizations: OrganizationModel[];
  selectOrganizationTooltip?: string;
  isUserLoggedIn: boolean;
};
export default function UserStatusClient(props: Readonly<Props>) {
  let info: OrganizationInfo = {
    organizations: props.organizations,
    selectedOrganization: undefined
  }
  return <UserStatus {...props} organizationInfo={info}/>;
}
