import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import LoginButton from "@/components/global/layout/UserStatus/LoginButton";
import { OrganizationModel } from "../OrganizationSelect/OrganizationModel";
import OrganizationSelect from "../OrganizationSelect/OrganizationSelect";
import LogoutButton from "./LogoutButton";

import "./UserStatus.css";
import { OrganizationInfo } from "@/app/layout";

type Props = {
  lang: string;
  organizations: OrganizationModel[];
  organizationInfo: OrganizationInfo
  selectOrganizationTooltip?: string;
  isUserLoggedIn: boolean;
};
export default function UserStatus(props: Readonly<Props>) {
  return (
    <div className="loginStatus">
      <IconLink
        href={`/${props.lang}/administration`}
        icon={AppIcons.Administration}
        id="permissions-link"
        hidden={!props.isUserLoggedIn || props.organizations === undefined || props.organizations.length === 0}
      />
      <IconLink
        href={`/${props.lang}/organizations/current`}
        icon={AppIcons.Organization}
        id="permissions-link"
        hidden={!props.isUserLoggedIn || props.organizations === undefined || props.organizations.length === 0}
      />
      <OrganizationSelect
        hidden={!props.isUserLoggedIn || props.organizations === undefined || props.organizations.length === 0}
        organizations={props.organizations}
        tooltip={props.selectOrganizationTooltip}
        organizationInfo={props.organizationInfo}
      />
      <IconLink
        icon={AppIcons.UserIcon}
        href="/profile/me"
        id="profile"
        hidden={!props.isUserLoggedIn}
      />
      <LogoutButton hidden={!props.isUserLoggedIn}/>
      <LoginButton hidden={props.isUserLoggedIn}/>
    </div>
  );
}
