"use client";

import IconLink from "@/components/common/IconLink/IconLink";
import { AppIcons } from "@/components/global/icons/AppIcons";
import HeaderBase from "@/components/global/layout/Header/HeaderBase";
import UserStatusClient from "@/components/global/layout/UserStatus/UserStatusClient";
import { useTranslation } from "@/i18n/client";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { OrganizationModel } from "../OrganizationSelect/OrganizationModel";
import "./Header.css";

type Props = {
  lang: string;
  isUserLoggedIn: boolean;
  organizations: OrganizationModel[];
};
export default function HeaderClient(props: Readonly<Props>) {
  const pathname = usePathname();
  const { t } = useTranslation(props.lang, "header");

  return <HeaderBase {...props} t={t} path={pathname}>
    <IconLink href="/help" icon={AppIcons.CircleQuestionIcon} id="help"/>
    <UserStatusClient
      lang={props.lang}
      isUserLoggedIn={props.isUserLoggedIn || false}
      organizations={props.organizations}
    />
  </HeaderBase>;
}
