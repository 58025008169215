"use client";
import { OrganizationModel } from "./OrganizationModel";
import "./OrganizationSelect.css";
import useOrganizationSelectController from "./useOrganizationSelectController";
import { OrganizationInfo } from "@/app/layout";

type Props = {
  organizations: OrganizationModel[];
  organizationInfo: OrganizationInfo
  tooltip?: string;
  hidden?: boolean;
};

export default function OrganizationSelect(props: Readonly<Props>) {
  const controller = useOrganizationSelectController({
    organizations: props.organizations,
  });
  const organizations = controller.organizations || [];

  return (
    <form
      className="form-inline my-2 my-lg-0 organization-form"
      hidden={props.hidden}
    >
      <select
        className="form-select"
        id="select-organization"
        value={controller.selectedOrganizationId}
        onChange={(event) => controller.onSelectionChange(event.target.value)
        }
      >
        {organizations.map((organization) => (
          <option key={organization.id} value={organization.id}>
            {organization.name}
          </option>
        ))}
      </select>
    </form>
  )
    ;
}
